import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/Layout'
import Contact from '../components/Contact'

export const ContactPageTemplate = ({ image, title, header, message }) => {

  return (
    <div>
      <div className="full-width-image margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundPosition: `top left`,
          backgroundAttachment: `fixed`,
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '150px',
            lineHeight: '1',
            justifyContent: 'space-around',
            alignItems: 'left',
            flexDirection: 'column',
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              boxShadow:
                'rgb(84, 96, 227) 0.5rem 0px 0px, rgb(84, 96, 227) -0.5rem 0px 0px',
              backgroundColor: 'rgb(84, 96, 227)',
              color: 'white',
              lineHeight: '1',
              padding: '0.25em',
            }}
          >
            {title}
          </h1>
        </div>
      </div>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <h3>{header}</h3>
                <div>
                  <p>{message}</p>
                </div>
              </div>
              <Contact />
            </div>
          </div>
        </div>
      </section>
    </div>    
  )
}

ContactPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
}

const ContactPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { markdownRemark: post } = data


  return (
    <Layout>
      <ContactPageTemplate
        image={frontmatter.image}      
        title={post.frontmatter.title}
        header={post.frontmatter.header}
        message={post.frontmatter.message}
      />
    </Layout>
  )
}

ContactPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ContactPage

export const aboutPageQuery = graphql`
  query ContactPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        header
        message
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }        
      }
    }
  }
`